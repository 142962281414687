import React from 'react';

import Layout from '../layouts/index';
import './terms.scss';

const TermsPage = (props) => (
    <Layout location={props.location}>
        <div className="terms-container">
            <h1>Terms of Service</h1>
        </div>
    </Layout>
);

export default TermsPage;
